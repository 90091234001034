import { Dialog } from "@angular/cdk/dialog";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ViewEncapsulation,
  booleanAttribute,
  inject,
  input,
  model,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AttachmentModel } from "@app/pages/task/+data-access";
import { DropZoneDirective } from "@app/pages/task/_task-playground/drop-zone.directive";
import { environment } from "@environments/environment";
import { InlineSVGModule } from "ng-inline-svg-2";
import { finalize } from "rxjs";
import { ImageViewerComponent } from "../image-viewer/image-viewer.component";
import { FileImagePreviewComponent } from "./file-image-preview.component";
import { FILE_EXTENSIONS, validateFile } from "@app/core/utils/file-validator";
import { NgClass } from "@angular/common";
import { FileCardComponent } from "../file-config/file-card/file-card.component";

export interface FileUploaderConfig {
  propertyId: string;
  modelId: string;
  model: string;
}

function dedup(files: File[]) {
  return files.reduce((results: File[], b: File) => {
    if (!results.some(x => x.name === b.name)) {
      results.push(b);
    }
    return results;
  }, []);
}

// TODO: configure this be able to only allow certain filetypes
@Component({
  standalone: true,
  selector: "app-file-uploader",
  styleUrl: "file-uploader.component.scss",
  templateUrl: "file-uploader.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // host: { class: "roam-file-uploader" },
  imports: [
    DropZoneDirective,
    FileImagePreviewComponent,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    InlineSVGModule,
    NgClass,
    FileCardComponent,
  ],
})
export class FileUploaderComponent {
  isShowFile = input<boolean>(true);
  #destroyRef = inject(DestroyRef);
  readonly data = model<AttachmentModel[]>([]);
  readonly containerText = input<string>("");
  readonly onlyShow = input(false, { transform: booleanAttribute });
  readonly config = input.required<FileUploaderConfig>();
  readonly isUploading = signal(false);
  readonly fileExtensionsMap = FILE_EXTENSIONS;
  readonly isFileValid = signal<boolean>(true);
  protected dialog = inject(Dialog);
  protected http = inject(HttpClient);

  onFileInputChanges(e: Event): void {
    const file = (e.target as HTMLInputElement).files!;
    
    if(file && validateFile(file[0])) {
      this.uploadFiles(dedup([...(e.target as any).files])); 
      this.isFileValid.set(true); 
    } else {
      this.isFileValid.set(false)
    }
  }

  onFileDropped(files: File[]): void {
    console.warn(files);
    this.uploadFiles(dedup(files));
  }

  // TODO: standalone upload these file http service!;
  #uploadMany(payload: any) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    const { attachments, propertyId, modelId, modelType } = payload;
    const body = new FormData();
    body.append("modelId", modelId);
    body.append("modelType", modelType);
    body.append("propertyId", propertyId);
    for (const file of attachments) {
      body.append("attachments[]", file, file.name);
    }
    return this.http.post<any[]>(`${environment.apiUrl}/attachments`, body, {
      withCredentials: true,
      headers,
    });
  }

  #deleteOne(id: string) {
    type Resp = { message: string };
    return this.http.delete<Resp>(`${environment.apiUrl}/attachments/${id}`, {
      withCredentials: true,
    });
  }

  uploadFiles(files: File[]): void {
    const config = this.config();
    this.isUploading.set(true);
    this.#uploadMany({
      modelType: config.model,
      propertyId: config.propertyId,
      attachments: files,
      modelId: config.modelId,
    })
      .pipe(
        finalize(() => this.isUploading.set(false)),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe({
        next: resp => {
          if (resp.length) {
            const attachments = resp.map(({ result }) => result);
            this.data.update(list => [...list, ...attachments]);
          }
        },
        error: () => {
          // TODO: handle http error!
        },
      });
  }

  openPreviewer(index: number): void {
    const previewUrls = this.data().map(item => {
      return { fileUrl: item.fileUrl, name: item.name };
    });
    if (!previewUrls.length) return;
    this.dialog.open(ImageViewerComponent, {
      // TODO: Make constants for these static strings values!!
      backdropClass: "roam-image-viewer-backdrop",
      panelClass: "roam-image-viewer-panel",
      id: "roamImageViewer",
      data: {
        activeIndex: index,
        urls: previewUrls,
      },
    });
  }

  removeOne(id: string): void {
    this.#deleteOne(id)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.data.update(items => {
            return items.filter(x => x.id !== id);
          });
        },
        error: () => {
          // TODO: alert user
        },
      });
  }
}
